import { firstValueFrom, lastValueFrom } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BrandingService } from '../shared/branding.service';
import { OverlayService } from '../shared/services/overlay-service.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {

  template = {
    title: '',
    message: '',
    showLink: false,
    linkPath: '',
    linkName: '',
  };

  errorDictionary = {
    default: {
      title: 'Unknown Error',
      message: 'An unknown error has occurred.  Please contact support@everpresent.com for help if this error persists.',
      showLink: false,
      linkPath: '',
      linkName: '',
    },
    offline: {
      title: 'Device is Offline',
      message: 'The application is unable to connect to the internet.',
      showLink: true,
      linkPath: '/home',
      linkName: 'Retry',
    }
  };

  constructor(
    private route: ActivatedRoute,
    public branding: BrandingService,
    private spinner: OverlayService,
  ) { }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.queryParams);
    const errorCode = params?.error || 'default';
    console.log(`error code ${errorCode}`);
    this.template = this.errorDictionary[errorCode] || this.errorDictionary.default;
    this.spinner.hide();
  }

}
