<ion-content>
  <ion-grid class="h100">
    <ion-row class="ion-justify-content-center h100">
      <ion-col size-md="6" size-lg="6" size-xl="4" class="align-center">
        <div>
          <ion-img id="logo" src="./assets/icon/{{branding.profile.logoFile}}"
            alt="{{branding.profile.name}}"></ion-img>
        </div>
        <div id="login-content">
          <div class="title-area">
            <h1>Welcome!</h1>
            <p>Please login to start enjoying your memories again.</p>
          </div>
          <app-login-button [intialScreen]="initialScreen" [loginHint]="loginHint"></app-login-button>
          <app-version-label></app-version-label>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>