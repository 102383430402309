import { Component, OnInit } from '@angular/core';
import { App, AppInfo } from '@capacitor/app';
import packageJson from '../../../../package.json';

@Component({
  selector: 'app-version-label',
  templateUrl: './version-label.component.html',
  styleUrls: ['./version-label.component.scss'],
})
export class VersionLabelComponent implements OnInit {

  public versionLabel: string;

  constructor() { }

  async ngOnInit() {
    let info: AppInfo = null;
    try {
      info = await App.getInfo();
    } catch (ex) { }

    if (info) {
      this.versionLabel = `v${info.version} (${info.build})`;
    } else if (packageJson?.version) {
      this.versionLabel = `v${packageJson?.version}`;
    }
  }

}
