import { ErrorComponent } from './error/error.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { AuthGuard } from './shared/auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { SignOutComponent } from './sign-out/sign-out.component';

const routes: Routes = [
  {
    path: 'sign-in',
    pathMatch: 'full',
    component: SignInComponent,
  },
  {
    path: 'callback',
    loadChildren: () =>
      import('./callback/callback.module').then((m) => m.CallbackPageModule),
  },
  {
    path: 'sign-out',
    pathMatch: 'full',
    component: SignOutComponent,
  },
  {
    path: 'logout',
    pathMatch: 'full',
    component: SignOutComponent,
  },
  {
    path: 'unauthorized',
    pathMatch: 'full',
    component: UnauthorizedComponent,
  },
  {
    path: 'error',
    pathMatch: 'full',
    component: ErrorComponent,
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./authenticated-container/authenticated-container.module').then((m) => m.AuthenticatedContainerModule),
  },
  {
    path: 'claim-account',
    canActivate: [AuthGuard],
    loadChildren: () => import('./claim-account/claim-account.module').then(m => m.ClaimAccountPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
