import { LookupBrandProfile } from '@shared/branding/branding-profiles';
import { Injectable } from '@angular/core';
import { BrandingProfile } from '@shared/models/brand-profile';

/**
 * Service provides the relevant branding for the site based on host
 */
@Injectable({
  providedIn: 'root'
})
export class BrandingService {

  private _profile: BrandingProfile = LookupBrandProfile('default');

  constructor() {
    this.lookupBrandingProfileBasedOnHostNameAndSet();
  }

  /** this is the active branding profile based on host */
  public get profile(): BrandingProfile {
    return this._profile;
  }

  private lookupBrandingProfileBasedOnHostNameAndSet() {
    const host = window.location.hostname;
    this._profile = LookupBrandProfile(host);
  }
}


