<ion-app>
  <ion-header *ngIf="isOverlay">
    <ion-toolbar>
      <ion-title>{{ progress }}</ion-title>
      <ion-progress-bar type="indeterminate"></ion-progress-bar>
    </ion-toolbar>
  </ion-header>
  <ion-content>|{{isOnline}}|</ion-content>
  <ion-router-outlet main></ion-router-outlet>
</ion-app>
<ngx-spinner name="root" type="ball-scale-multiple" [fullScreen]="true">{{progress}}</ngx-spinner>
