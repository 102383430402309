import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.scss'],
})
export class UnauthorizedComponent implements OnInit {

  isAuthenticated = false;

  constructor(
    private auth: AuthService,
  ) { }

  async ngOnInit() {
    this.isAuthenticated = await lastValueFrom(this.auth.isAuthenticated$);
  }

}
