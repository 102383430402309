import { Pipe, PipeTransform } from '@angular/core';
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(phoneValue: number | string): string {
    const ayt = new AsYouType('US');
    try {
      phoneValue = phoneValue.toString();
      if (phoneValue.includes('(') && !phoneValue.includes(')')) {
        return phoneValue.replace('(', '').substring(0, phoneValue.length - 2);
      }
      return ayt.input(phoneValue);
    } catch (error) {
      console.log(error);
      return phoneValue as string;
    }
  }
}
