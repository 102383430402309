import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
/* eslint-disable @typescript-eslint/member-ordering */
import { Subject, mergeMap, catchError, of, timeout } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HostListener, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BrandingService } from './branding.service';

@Injectable({
  providedIn: 'root'
})
export class ConnectivityService {

  private _isOnlineSubject = new BehaviorSubject<boolean>(true);
  private _isOnlineObservable: Observable<boolean> = null;
  browserIsOnline = true;

  constructor(
    private http: HttpClient,
    private branding: BrandingService,
  ) { }

  get isOnline(): Observable<boolean> {
    if (!this._isOnlineObservable) {
      this._isOnlineObservable = this.initializeIsOnlineObservable();
    }

    return this._isOnlineObservable;
  }

  async isOnlinePromise(): Promise<boolean> {
    return firstValueFrom(this.isOnline);
  }

  private initializeIsOnlineObservable(): Observable<boolean> {
    return this.checkIsOnline()
      .pipe(
        timeout(10000),
        catchError((err, c) => {
          console.warn('can\'t get ping');
          return of(false);
        }),
        mergeMap((online) => {
          this._isOnlineSubject.next(online);
          return this._isOnlineSubject.asObservable();
        }),
      );
  }

  private checkIsOnline(): Observable<boolean> {
    if (this.browserIsOnline) {
      return this.http.get<boolean>(`${environment[this.branding.profile.name.toLowerCase()].apiUrl}ping`, {});
    } else {
      return of(false);
    }
  }

  @HostListener('window:offline')
  setNetworkOffline(): void {
    console.log('window is offline');
    this.browserIsOnline = false;
  }

  @HostListener('window:online')
  setNetworkOnline(): void {
    console.log('window is online');
    this.browserIsOnline = true;
  }
}
