import { Nanogallery2Component } from './nanogallery2/nanogallery2.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginButtonComponent } from './login-button.component';
import { IonicModule } from '@ionic/angular';
import { SignUpButtonComponent } from './sign-up-button.component';
import { SortablejsModule } from 'ngx-sortablejs';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ReorderAlbumComponent } from './reorder-album/reorder-album.component';
import { SortAssetsPipe } from './pipes/sort-assets.pipe';
import { FilterDropdownComponent } from './filter-dropdown/filter-dropdown.component';
import { SortDropdownComponent } from './sort-dropdown/sort-dropdown.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { TotalComponent } from './total/total.component';
import { EditModeComponent } from './edit-mode/edit-mode.component';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { AlbumsComponent } from './albums/albums.component';
import { RouterModule } from '@angular/router';
import { AddToAlbumComponent } from './add-to-album/add-to-album.component';
import { IsWriteablePipe } from './pipes/is-writeable.pipe';
import { AddToCurrentAlbumComponent } from './add-to-current-album/add-to-current-album.component';
import { FileSaverModule } from 'ngx-filesaver';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { NgxPopperModule } from 'ngx-popper';
import { JobsComponent } from './jobs/jobs.component';
import { SharingComponent } from './sharing/sharing.component';
import { ShareButtonsPopupModule } from 'ngx-sharebuttons/popup';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { OverlayModule } from '@angular/cdk/overlay';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faCommentsDollar } from '@fortawesome/free-solid-svg-icons/faCommentsDollar';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { PrivateSharingComponent } from './private-sharing/private-sharing.component';
import { SharingDetailsPage } from '../sharing-details/sharing-details.page';
import { CaptionPopoverComponent } from './caption-popover/caption-popover.component';
import { SafePipe } from './pipes/safe.pipe';
import { SwiperHomeComponent } from './swiper-home/swiper-home.component';
import { SwiperModule } from 'swiper/angular';
import { BottomHomeComponent } from './bottom-home/bottom-home.component';
import { FilterSortPopoverComponent } from './filter-sort-popover/filter-sort-popover.component';
import { QuickLinksDesktopComponent } from './quick-links-desktop/quick-links-desktop.component';
import { QuickLinksMobileComponent } from './quick-links-mobile/quick-links-mobile.component';
import { AccountComponent } from './account/account.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PhoneMaskDirective } from './phone-mask.directive';
import { ReactiveFormsModule } from '@angular/forms';
import { PhonePipe } from './pipes/phone.pipe';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { PhotoLibrary } from '@awesome-cordova-plugins/photo-library/ngx';
import { SmallThumbnailsComponent } from './small-thumbnails/small-thumbnails.component';
import { ListViewComponent } from './list-view/list-view.component';
import { AlbumHierarchyViewComponent } from './album-hierarchy-view/album-hierarchy-view.component';
import { CdkTreeModule } from '@angular/cdk/tree';
import { AllowDownloadComponent } from './allow-download/allow-download.component';
import { TrialReminderComponent } from './trial-reminder/trial-reminder.component';

@NgModule({
  declarations: [
    LoginButtonComponent,
    SignUpButtonComponent,
    Nanogallery2Component,
    EditModeComponent,
    ContextMenuComponent,
    ReorderAlbumComponent,
    SortAssetsPipe,
    FilterDropdownComponent,
    SortDropdownComponent,
    LoadingSpinnerComponent,
    TotalComponent,
    TrialReminderComponent,
    EditModeComponent,
    AlbumsComponent,
    AddToAlbumComponent,
    IsWriteablePipe,
    AddToCurrentAlbumComponent,
    ToolbarComponent,
    JobsComponent,
    SharingComponent,
    SharingComponent,
    PrivateSharingComponent,
    SharingDetailsPage,
    CaptionPopoverComponent,
    SafePipe,
    SwiperHomeComponent,
    BottomHomeComponent,
    FilterSortPopoverComponent,
    QuickLinksDesktopComponent,
    QuickLinksMobileComponent,
    AccountComponent,
    ChangePasswordComponent,
    PhoneMaskDirective,
    PhonePipe,
    SmallThumbnailsComponent,
    ListViewComponent,
    AlbumHierarchyViewComponent,
    AllowDownloadComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    SortablejsModule,
    FormsModule,
    NgxSpinnerModule,
    RouterModule,
    FileSaverModule,
    NgxPopperModule,
    ShareButtonsPopupModule,
    ShareIconsModule,
    OverlayModule,
    SwiperModule,
    ReactiveFormsModule,
    CdkTreeModule,
  ],
  exports: [
    LoginButtonComponent,
    SignUpButtonComponent,
    Nanogallery2Component,
    ReorderAlbumComponent,
    SortAssetsPipe,
    FilterDropdownComponent,
    SortDropdownComponent,
    LoadingSpinnerComponent,
    TotalComponent,
    TrialReminderComponent,
    EditModeComponent,
    ContextMenuComponent,
    AlbumsComponent,
    AddToAlbumComponent,
    IsWriteablePipe,
    AddToCurrentAlbumComponent,
    ToolbarComponent,
    JobsComponent,
    SharingComponent,
    SharingComponent,
    SharingDetailsPage,
    CaptionPopoverComponent,
    SwiperHomeComponent,
    BottomHomeComponent,
    FilterSortPopoverComponent,
    QuickLinksDesktopComponent,
    QuickLinksMobileComponent,
    AccountComponent,
    ChangePasswordComponent,
    SmallThumbnailsComponent,
    ListViewComponent,
    AlbumHierarchyViewComponent,
    AllowDownloadComponent,
  ],
  providers: [PhonePipe, SocialSharing, PhotoLibrary],
})
export class SharedModule {
  constructor(iconLibrary: FaIconLibrary) {
    iconLibrary.addIcons(faTimesCircle);
    iconLibrary.addIcons(faCommentsDollar);
  }
}
