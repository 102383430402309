import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BrandingService } from './branding.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private branding: BrandingService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.includes('ping')) {
      return next.handle(req);
    }
    return this.authService
      .getAccessTokenSilently({ timeoutInSeconds: 10 })
      .pipe(
        take(1),
        catchError((error) => {
          console.error(
            'A error occurred when adding authorization token to request.',
            error
          );
          return next.handle(req);
        }),
        switchMap((authToken) => {
          if (req.url.includes(environment[this.branding.profile.name.toLowerCase()].apiUrl)) {
            req = req.clone({
              // eslint-disable-next-line @typescript-eslint/naming-convention
              setHeaders: { Authorization: `Bearer ${authToken}` },
            });
          }
          return next.handle(req);
        })
      );
  }
}
