import { Browser } from '@capacitor/browser';
import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Capacitor } from '@capacitor/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BrandingService } from '../shared/branding.service';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss'],
})
export class SignOutComponent implements OnInit {

  constructor(
    public auth: AuthService,
    public branding: BrandingService,
  ) { }

  async ngOnInit() {
    // this.authService.logout();
  }

  async logout() {
    // Use the SDK to build the logout URL
    const returnTo = Capacitor.isNativePlatform()
      ? environment[this.branding.profile.name.toLowerCase()].mobileLogoutUrl
      : `${window.location.origin}/sign-out`;

    const url = await lastValueFrom(this.auth.buildLogoutUrl({ returnTo }));
    // Call the logout function, but only log out locally
    this.auth.logout({ localOnly: true });
    // Redirect to Auth0 using the Browser plugin, to clear the user's session
    Browser.open({ url, windowName: '_self', presentationStyle: 'popover' });
  }

}
