<ion-header>
  <ion-toolbar>
    <ion-title>Unauthorized Access</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <h1>Unauthorized Access</h1>
  <p>You do not have access to view any memories on this tool.</p>
  <div>
    <app-sign-in *ngIf="!isAuthenticated"></app-sign-in>
    <app-sign-out *ngIf="isAuthenticated"></app-sign-out>
  </div>
  <p>!!!</p>
</ion-content>