import { OverlayService } from './shared/services/overlay-service.service';
import { ConnectivityService } from './shared/connectivity.service';
import { Component, NgZone, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import {
  map,
  mergeMap,
  startWith,
  switchMap,
  take,
  tap,
  timeoutWith,
} from 'rxjs/operators';
import { Browser } from '@capacitor/browser';
import { App } from '@capacitor/app';
import { environment } from 'src/environments/environment';
import { Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';
import { BrandingService } from './shared/branding.service';
import {
  Angulartics2GoogleAnalytics,
  Angulartics2GoogleTagManager,
  Angulartics2Segment,
} from 'angulartics2';
import { NgxSpinnerService } from 'ngx-spinner';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import {
  AppUpdate,
  AppUpdateInfo,
  AppUpdateAvailability,
} from '@capawesome/capacitor-app-update';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public name: string;
  public email: string;
  loginModelOpen = false;
  isOnline: boolean;
  progress = 'Loading...';
  isOverlay = false;
  private isOnlineSubscription: Subscription;

  constructor(
    public auth: AuthService,
    private ngZone: NgZone,
    private router: Router,
    private connectivity: ConnectivityService,
    private platform: Platform,
    public branding: BrandingService,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private angulartics2Segment: Angulartics2Segment,
    private overlay: OverlayService,
    private spinner: NgxSpinnerService,
    private alertController: AlertController
  ) {
    // this.angulartics2GoogleTagManager.startTracking();
    // this.angulartics2GoogleAnalytics.startTracking();
    this.angulartics2Segment.startTracking();
    this.initializeApp();
  }

  initializeApp() {
    console.log('Trying this');
    this.platform.ready().then(() => {
      console.log('Here: ', this.platform);
      if (this.platform.is('capacitor')) {
        console.log('is capacitor');
        this.getCurrentAppVersion();
        this.checkForUpdate();
      }
    });
  }

  getCurrentAppVersion = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    console.log('Current app version:', result.currentVersionName);
  };

  async ngOnInit(): Promise<void> {
    this.changeBranding();

    if (this.platform.width() <= 1024) {
      await ScreenOrientation.lock({ orientation: 'portrait-primary' });
    }

    this.isOnlineSubscription = this.connectivity.isOnline
      .pipe(tap((value) => console.log(`isOnline: ${value}`)))
      .subscribe((value) => (this.isOnline = value));

    // Use Capacitor's App plugin to subscribe to the `appUrlOpen` event
    App.addListener('appUrlOpen', ({ url }) => {
      // Must run inside an NgZone for Angular to pick up the changes
      // https://capacitorjs.com/docs/guides/angular
      this.ngZone.run(() => {
        console.log(`reached app url open with: ${url}`);
        if (
          url?.startsWith(
            environment[this.branding.profile.name.toLowerCase()]
              .mobileCallbackUrl
          )
        ) {
          // If the URL is an authentication callback URL..
          console.log('app url matched callback url.');
          if (
            url.includes('state=') &&
            (url.includes('error=') || url.includes('code='))
          ) {
            // Call handleRedirectCallback and close the browser
            console.log(
              'app state found in callback, handling authentication then closing in-app browser.'
            );
            this.auth
              .handleRedirectCallback(url)
              .pipe(
                take(1),
                mergeMap(() => {
                  Browser.close();
                  return this.router.navigateByUrl('/callback');
                })
              )
              .subscribe();
          } else {
            Browser.close();
          }
        } else if (
          url?.startsWith(
            environment[this.branding.profile.name.toLowerCase()]
              .mobileLogoutUrl
          )
        ) {
          Browser.close();
          this.router.navigateByUrl('/sign-in');
        }
      });
    });

    this.overlay.overlayTextTrigger.subscribe((value) => {
      this.progress = value;
      console.log(value);
    });
    this.overlay.overlayTrigger.subscribe(async (flag) => {
      if (flag) {
        // this.isOverlay = true;
        await this.spinner.show('root');
      } else {
        // this.isOverlay = false;
        await this.spinner.hide('root');
      }
    });
  }

  /**
   * Used to simulate app update functions locally
   *
   * @returns AppUpdateInfo object
   */
  private async simulateCheckForUpdate() {
    return {
      updateAvailability: AppUpdateAvailability.UPDATE_AVAILABLE,
      immediateUpdateAllowed: false,
      flexibleUpdateAllowed: false,
      currentVersion: '1.0.0',
      availableVersion: '2.0.0',
    };
  }

  private async checkForUpdate() {
    const result = await AppUpdate.getAppUpdateInfo();
    // const result = await this.simulateCheckForUpdate(); // for testing

    if (result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE) {
      if (this.platform.is('android')) {
        if (result.immediateUpdateAllowed) {
          await AppUpdate.performImmediateUpdate();
        } else if (result.flexibleUpdateAllowed) {
          await AppUpdate.startFlexibleUpdate();
          AppUpdate.addListener('onFlexibleUpdateStateChange', async () => {
            const alert = await this.alertController.create({
              header: 'Update Downloaded',
              message:
                'The update has been downloaded. Would you like to restart the app to complete the update now?',
              buttons: [
                {
                  text: 'Later',
                  role: 'cancel',
                },
                {
                  text: 'Restart Now',
                  handler: () => {
                    AppUpdate.completeFlexibleUpdate();
                  },
                },
              ],
            });

            await alert.present();
          });
        } else {
          // Handle the case where an update is not allowed
          const alert = await this.alertController.create({
            header: 'Update Available',
            message:
              'A new version of the app is available. Check the Play Store for the newest version.',
            buttons: [
              {
                text: 'Ok',
                role: 'cancel',
              },
            ],
          });
          await alert.present();
        }
      } else if (this.platform.is('ios')) {
        const alert = await this.alertController.create({
          header: 'Update Available',
          message:
            'A new version of the app is available. Would you like to update now?',
          buttons: [
            {
              text: 'No',
              role: 'cancel',
            },
            {
              text: 'Update Now',
              handler: () => {
                AppUpdate.openAppStore();
              },
            },
          ],
        });
        await alert.present();
      }
    }
  }

  private changeBranding() {
    const favicon = document.querySelector('[rel=icon]');
    favicon.setAttribute('href', `${this.branding.profile.favIcon}`);
    document.title = this.branding.profile.name;
  }
}
