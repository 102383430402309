import { VersionLabelModule } from './shared/version-label/version-label.module';
import { VersionLabelComponent } from './shared/version-label/version-label.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OverlayService } from './shared/services/overlay-service.service';
import { BrandingService } from './shared/branding.service';
import { ConnectivityService } from './shared/connectivity.service';
import { ErrorComponent } from './error/error.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { SignOutComponent } from './sign-out/sign-out.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from '@auth0/auth0-angular';
import { SharedModule } from './shared/shared.module';
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { UserService } from './shared/services/user.service';
import { SortablejsModule } from 'ngx-sortablejs';
import { AuthInterceptor } from './shared/auth-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPopperModule } from 'ngx-popper';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { ToastrModule } from 'ngx-toastr';

// Switch site to test just8mm credentials locally
const site = window.location.hostname.toLowerCase().includes('8mm')
  ? 'just8mm'
  : 'everpresent';
// const site = 'just8mm';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignOutComponent,
    UnauthorizedComponent,
    ErrorComponent,
  ],
  // ...

    imports: [
      CommonModule,
      BrowserModule,
      HttpClientModule,
      BrowserAnimationsModule,
      IonicModule.forRoot(),
      AppRoutingModule,
      SharedModule,
      NgxSpinnerModule,
      AuthModule.forRoot({
        domain: environment[site].auth0Domain,
        clientId: environment[site].auth0ClientId,
        redirectUri: Capacitor.isNativePlatform()
          ? environment[site].mobileCallbackUrl
          : `${window.location.origin}/callback`,
        /* Uncomment the following lines for better support  in browsers like Safari where third-party cookies are blocked.
                See https://auth0.com/docs/libraries/auth0-single-page-app-sdk#change-storage-options for risks.
              */
        cacheLocation: 'localstorage',
        useRefreshTokens: true,
        audience: 'https://everpresent/users/post',
      }),
      HttpClientModule,
      SortablejsModule.forRoot({}),
      NgxPopperModule.forRoot({}),
      Angulartics2Module.forRoot({
        pageTracking: {
          clearIds: true,
          clearQueryParams: true,
        },
        developerMode: false,
      }),
      VersionLabelModule,
      ToastrModule.forRoot(), // Add ToastrModule to the imports array
    ],
  providers: [
    BrandingService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    ConnectivityService,
    OverlayService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
