import { BrandingProfile } from './../models/brand-profile';
import { Brand } from "../models/enums";

const just8mm: BrandingProfile = {
  brand: Brand.Just8mm,
  name: "Just8mm",
  logoFile: "Just8mm transparent.png",
  favIcon: "assets/icon/just8mm-logo icon.jpg",
  auth0Logo: "Just8mm+transparent.png",
  watermark: "assets/just8mm_watermark_0.25_opacity.png",
  inviteIcon: "just8mm+button.png",
  tangoLink: "78a3a802360f40268b24f3a007bf1765",
  marketingWebsite: 'https://Just8mm.com'
};

const everpresent: BrandingProfile = {
  brand: Brand.EverPresent,
  name: "EverPresent",
  logoFile: "logo-large-removebg.png",
  favIcon: "assets/icon/square-logo.ico",
  auth0Logo: "HI+RES+TRANSPARENT+LOGO-01.png",
  watermark: "assets/watermark_0.25_opacity.png",
  inviteIcon: "Signup_Image.png",
  tangoLink: "65164a830ff944c8a4c85a60ef698684",
  marketingWebsite: 'https://everpresent.com',
};

type BrandingKey = string | Brand;
type BrandingProfileLookup = { [key in BrandingKey as string]?: BrandingProfile }

const brandingProfileLookup: BrandingProfileLookup = {
  default: everpresent,
  "app.just8mm.com": just8mm,
  "test.just8mm.com": just8mm,
  "just8mm": just8mm,
  [Brand.EverPresent]: everpresent,
  [Brand.Just8mm]: just8mm,
  "app.everpresent.com": everpresent,
  "test.everpresent.com": everpresent,
  // "localhost:4200": just8mm, // comment in and out to use this just8mm.com profile
  // "localhost": just8mm, // comment in and out to use this just8mm.com profile
  "P0BmLaAYu1lNNUcUWtvN59g1chjhN1qx": just8mm, // auth zero application for just8mm prod,
  "crsq8URBu8E3j9c8OZCb5x2yjUy4Scux": just8mm, // auth zero application for just8mm test,
};

/**
 * Looks up the applicable brand profile based on the origin string or the specific brand enumeration
 * @param key origin string with or without https or a brand enum
 * @returns brand profile for key or default profile of EverPresent
 */
export function LookupBrandProfile(key: BrandingKey): BrandingProfile {
  if (!key) {
    return brandingProfileLookup['default'];
  }

  let cleanKey = key;
  if (typeof key === 'string') {
    cleanKey = key.toString().toLowerCase().replace('https://', '').replace('http://', '');
  }

  const brandingProfile = brandingProfileLookup[cleanKey] || brandingProfileLookup['default'];

  console.debug('**********************')
  console.debug(`* branding key ${cleanKey} ---> profile ${brandingProfile.name}`);
  console.debug('**********************')

  return brandingProfile;
}

/**
 * Looks up the applicable brand based on the string entered.
 * @param key origin string with or without https
 * @returns brand enum for key or default brand of EverPresent
 */
export function LookupBrand(key: string): Brand {
  var profile = LookupBrandProfile(key);
  return profile?.brand ?? Brand.EverPresent;
}