<ion-content class="safe-area-padding">
  <ion-row class="ion-justify-content-center h100">
    <ion-col size-md="6" size-lg="6" size-xl="4" class="align-center">
      <ion-card>
        <img src="/assets/icon/{{branding.profile.logoFile}}" alt="{{branding.profile.logoFile}}" />
        <ion-card-header>
          <ion-card-title>
            Error
          </ion-card-title>
          <ion-card-subtitle>{{template.title}}</ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
          <p>{{template.message}}</p>
          <p>
            <a *ngIf="template.showLink" [routerLink]="[ template.linkPath ]">{{template.linkName}}</a>
          </p>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</ion-content>