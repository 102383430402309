import { catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, lastValueFrom, Observable, of } from 'rxjs';
import { IUser } from '@shared/models/IUser';
import { AuthService } from '@auth0/auth0-angular';
import { map, mergeMap, shareReplay, skipWhile, take, throttleTime } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '@auth0/auth0-spa-js';
import { BrandingService } from '../branding.service';
import { Angulartics2GoogleAnalytics, Angulartics2Segment } from 'angulartics2';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public iUser$: Observable<IUser>;
  user: IUser;

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private branding: BrandingService,
    private angulartics2Segment: Angulartics2Segment,
    // private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
  ) {
    this.iUser$ = auth.user$.pipe(
      /**
       * I found this tends to fire twice when a new user is received. Throttle update to every
       * 5 seconds so subscribing observables are not refreshed more often then necessary.
       */
      // throttleTime(5000),
      skipWhile(user => !user),
      take(1),
      map<User, string>((user) => user.email),
      mergeMap((email) =>
        this.http.get<IUser>(`${environment[branding.profile.name.toLowerCase()].apiUrl}user/me`)
      ),
      tap(u => { this.user = u; }),
      tap(u => {
        if (u) {
          this.angulartics2Segment.setUserProperties({
            userId: u._id,
            name: u.fullName,
            email: u.email,
            zohoId: u.zohoId,
            zohoCustomerNumber: u.zohoCustomerNumber,
            brand: u.brand
          });
          // this.angulartics2GoogleAnalytics.setUsername(u.email); // commented out until privacy policy is updated
        }
      }),
      catchError((err, c) => {
        console.log(err);
        return of(null);
      }),
      shareReplay(1)
    );
  }

  async updateUser(user: IUser): Promise<IUser> {
    const updatedUser$ = this.http.put<IUser>(
      `${environment[this.branding.profile.name.toLowerCase()].apiUrl}users`,
      user
    );

    return await firstValueFrom(updatedUser$);
  }

  async changePassword(): Promise<any> {
    try {
      const ticket = await firstValueFrom(this.http.get<any>(
        `${environment[this.branding.profile.name.toLowerCase()].apiUrl}user/change-password`
      ));
      return ticket.ticket;
    } catch (error) {
      return null;
    }
  }
}
