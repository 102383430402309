export enum Status {
  PARTIAL = "PARTIAL",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum ZohoJobStatus {
  PARTIAL = "Partial Access",
  ACTIVE = "Full Access",
  INACTIVE = "Inactive",
}

/** Translates the job status as found in Zoho into the EverPresent App Status */
export const ZohoJobStatusTranslation: { [key in ZohoJobStatus]: Status } = {
  [ZohoJobStatus.ACTIVE]: Status.ACTIVE,
  [ZohoJobStatus.PARTIAL]: Status.PARTIAL,
  [ZohoJobStatus.INACTIVE]: Status.INACTIVE,
};

export enum AssetType {
  DOCUMENT = "DOCUMENT",
  PHOTO = "PHOTO",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  UNKNOWN = "UNKNOWN",
}

export enum Flag {
  MissingEmail = 0,
}

export enum Brand {
  EverPresent = 0,
  Just8mm = 1,
}

export function getAssetType(mimeType: string): AssetType {
  const { JPG, PDF, PNG, AUDIO_MP3, AUDIO_MP4, OGG, TIFF, VIDEO_MP3, VIDEO_MP4, WAV, WEBM, WEBP, MOV, AVI } = MimeType;

  switch (mimeType?.toLowerCase()) {
    case JPG:
    case PNG:
    case TIFF:
    case WEBP:
      return AssetType.PHOTO;
    case PDF:
      return AssetType.DOCUMENT;
    case VIDEO_MP4:
    case OGG:
    case WEBM:
    case VIDEO_MP3:
    case MOV:
    case AVI:
      return AssetType.VIDEO;
    case WAV:
    case AUDIO_MP3:
    case AUDIO_MP4:
      return AssetType.AUDIO;
  }

  return AssetType.UNKNOWN;
}

export enum MimeType {
  JPG = "image/jpeg",
  PDF = "application/pdf",
  PNG = "image/png",
  TIFF = "image/tiff",
  WEBP = "image/webp",
  VIDEO_MP3 = "video/mp3",
  VIDEO_MP4 = "video/mp4",
  WEBM = "video/webm",
  OGG = "video/ogg",
  AUDIO_MP3 = "audio/mpeg",
  WAV = "audio/wav",
  AUDIO_MP4 = "audio/mp4",
  ZIP = "application/zip",
  MOV = "video/quicktime",
  AVI = "video/avi",
  JSON = "application/json",
}

export function mimeTypeToExtension(mime: MimeType): string {
  const { JPG, PDF, PNG, AUDIO_MP3, AUDIO_MP4, OGG, TIFF, VIDEO_MP3, VIDEO_MP4, WAV, WEBM, WEBP, ZIP, MOV, AVI } = MimeType;

  switch (mime) {
    case JPG:
      return ".jpg";
    case PDF:
      return ".pdf";
    case PNG:
      return ".png";
    case TIFF:
      return ".tiff";
    case WEBP:
      return ".webp";
    case MOV:
      return ".mov";
    case AVI:
      return ".avi";
    case VIDEO_MP4:
    case AUDIO_MP4:
      return ".mp4";
    case VIDEO_MP3:
    case AUDIO_MP3:
      return ".mp3";
    case WEBM:
      return ".webm";
    case WAV:
      return ".wav";
    case OGG:
      return ".ogg";
    case ZIP:
      return ".zip";
  }
}

export function fileExtensionToMimeType(ext: string): MimeType {
  const { JPG, PDF, PNG, AUDIO_MP3, OGG, TIFF, VIDEO_MP4, WAV, WEBM, WEBP, ZIP, MOV, AVI } = MimeType;

  ext = ext.toLowerCase().replace(".", "");

  switch (ext) {
    case "jpg":
    case "jpeg":
      return JPG;
    case "pdf":
      return PDF;
    case "png":
      return PNG;
    case "tiff":
    case "tif":
      return TIFF;
    case "webp":
      return WEBP;
    case "mp4":
      return VIDEO_MP4;
    case "mp3":
      return AUDIO_MP3;
    case "webm":
      return WEBM;
    case "mov":
    case "quicktime":
      return MOV;
    case "avi":
    case "x-msvideo":
      return AVI;
    case "wav":
      return WAV;
    case "ogg":
      return OGG;
    case "zip":
      return ZIP;
    default:
      "application/octet-stream";
  }
}

export enum LogType {
  SOFT_DELETE = "soft_delete",
  RESTORE = "restore",
  PERMANENT_DELETE = "permanent_delete",
  ADMIN_ASSET_DELETE = "admin_asset_delete",
  ADMIN_ALBUM_DELETE = "admin_album_delete",
  ADMIN_JOB_DELETE = "admin_job_delete",
}

export enum MenuContext {
  ASSETS = "assets",
  ALBUM_NOT_WRITEABLE = "album_not_writeable",
  ALBUM_WRITEABLE = "album_writeable",
  FAVORITES = "favorites",
  TRASH = "trash",
  SHARED = "shared",
  PROJECT = "project",
}

export enum BatchDownloadStatus {
  REQUESTED = 0,
  IN_PROGRESS = 1,
  COMPLETE = 2,
  ERROR = 3,
}

export enum ShareAccess {
  PUBLIC = 0,
  VIEWER = 1,
  COMMENTER = 2,
  EDITOR = 3,
  CO_OWNER = 4,
}
