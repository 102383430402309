import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  overlayTrigger: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  overlayTextTrigger: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor() { }

  show(text?: string) {
    if (text) { this.updateText(text); }
    this.overlayTrigger.next(true);
  }

  hide() {
    this.updateText(null);
    this.overlayTrigger.next(false);
  }

  updateText(text: string) {
    this.overlayTextTrigger.next(text);
  }
}
