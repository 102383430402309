import { Component, Input } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { RedirectLoginOptions } from '@auth0/auth0-spa-js';
import { Browser } from '@capacitor/browser';
import { lastValueFrom } from 'rxjs';
import { mergeMap, map, tap } from 'rxjs/operators';
import { BrandingService } from './branding.service';

@Component({
  selector: 'app-login-button',
  template: `<ion-button color="primary"
  expand="full" size="large" (click)="login()">Login</ion-button>`,
})
export class LoginButtonComponent {
  //waiting for merge to set this correctly, but it works
  private _initialScreen = 'login';
  private _loginHint = '';
  constructor(public auth: AuthService, public branding: BrandingService) { }
  get initialScreen(): string {
    return this._initialScreen || 'login';
  }
  @Input() set initialScreen(value: string) {
    this._initialScreen = value;
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  get loginHint(): string {
    return this._loginHint || '';
  }
  @Input() set loginHint(value: string) {
    this._loginHint = value;
  }

  async login() {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const params: RedirectLoginOptions<any> = {
      prompt: 'select_account',
      fragment: this.initialScreen,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      login_hint: this.loginHint,
      logo: this.branding.profile.auth0Logo
    };
    console.log(params);
    const loginUrl = await lastValueFrom(this.auth.buildAuthorizeUrl(params));
    // Pass params through buildAuthorizeUrl
    // if (this.loginHint) {
    //   loginUrl = loginUrl.includes('?')
    //   ? `${loginUrl}&fragment=${this.initialScreen}&login_hint=${this.loginHint}&logo=${this.branding.profile.auth0Logo}`
    //   : `${loginUrl}?fragment=${this.initialScreen}&login_hint=${this.loginHint}&logo=${this.branding.profile.auth0Logo}`;
    // } else {
    //   loginUrl = loginUrl.includes('?')
    //   ? `${loginUrl}&fragment=${this.initialScreen}&logo=${this.branding.profile.auth0Logo}`
    //   : `${loginUrl}?fragment=${this.initialScreen}&logo=${this.branding.profile.auth0Logo}`;
    // }
    console.log(loginUrl);
    Browser.open({ url: loginUrl, windowName: '_self', presentationStyle: 'popover' });
  }
}
