import { VersionLabelComponent } from './version-label.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [
    VersionLabelComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [VersionLabelComponent]
})
export class VersionLabelModule { }
