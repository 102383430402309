// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  everpresent: {
    mobileCallbackUrl: 'com.everpresent.everpresent.test://callback',
    auth0Domain: 'everpresent.us.auth0.com',
    auth0ClientId: 'uPnilA8IvJQpFHh2K56vxuaXyGd1X8Hv',
    mobileLogoutUrl: 'com.everpresent.everpresent.test://sign-out',
    shareUrl: 'https://share-test.everpresent.com/test/share/',
    apiUrl: 'https://api-test.everpresent.com/test/',
  },
  just8mm: {
    mobileCallbackUrl: 'com.just8mm.just8mm://callback',
    auth0Domain: 'everpresent.us.auth0.com',
    auth0ClientId: 'crsq8URBu8E3j9c8OZCb5x2yjUy4Scux',
    mobileLogoutUrl: 'com.just8mm.just8mm://sign-out',
    shareUrl: 'https://share-test.just8mm.com/test/share/',
    apiUrl: 'https://api-test.just8mm.com/test/',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
