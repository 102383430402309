import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { Browser } from '@capacitor/browser';
import { BrandingService } from '../shared/branding.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {

  appState: string;
  initialScreen: string;
  loginHint: string;

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    public branding: BrandingService,
  ) { }

  async ngOnInit() {
    const params = await firstValueFrom(this.route.queryParams);
    console.log(params);
    this.initialScreen = params.initial_screen ?? 'login';
    this.appState = params.page;
    this.loginHint = params.loginHint;

    const isAuthenticated = await firstValueFrom(this.auth.isAuthenticated$);
    if (isAuthenticated) {
      if (this.appState) {
        this.router.navigate([this.appState]);
      } else {
        this.router.navigate(['home'], {});
      }
    } else {
      // this.auth.loginWithRedirect(options);
      await this.login();
    }
  }

  async login(): Promise<void> {
    const loginUrl = await lastValueFrom(this.auth.buildAuthorizeUrl({
      prompt: 'select_account',
      appState: this.appState,
      fragment: this.initialScreen,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      login_hint: this.loginHint,
      logo: this.branding.profile.auth0Logo
    }));

    // This all gets sent in with buildAuthorizeUrl
    // loginUrl = loginUrl.includes('?')
    //   ? `${loginUrl}&initialScreen=login&logo=${this.branding.profile.auth0Logo}`
    //   : `${loginUrl}?initialScreen=login&logo=${this.branding.profile.auth0Logo}`;

    // loginUrl = this.loginHint
    //   ? `${loginUrl}&login_hint=${this.loginHint}`
    //   : loginUrl;

    Browser.open({ url: loginUrl, windowName: '_self', presentationStyle: 'popover' });
  }
}
