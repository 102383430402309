<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size-md="6" push-md="3" size-lg="4" push-lg="4" size-xl="2" push-xl="5">
        <div>
          <ion-img id="logo" src="./assets/icon/{{branding.profile.logoFile}}"
            alt="{{branding.profile.name}}"></ion-img>
        </div>
        <div id="login-content">
          <div class="title-area">
            <h1>Thank you!</h1>
            <h1>You are now signed out.</h1>
          </div>
          <app-login-button></app-login-button>
          <app-version-label></app-version-label>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>